/* Notes:  this component  works well for bulk edit so far,
     might need some works here then re-use it  when we migrate event detail page
*/

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnChanges,
    OnInit,
    ViewEncapsulation,
    Input,
    Output,
    EventEmitter,
} from "@angular/core";
import { S25EditableAbstract } from "../s25.editable.abstract";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { Rules } from "../../s25-rule-tree/s25.rule.const";
import { ModalImageI } from "../../modal/modals/modal.image.upload.component";
import { CustomAttributeService } from "../../../services/custom.attribute.service";
import { DropDownItem } from "../../../pojo/DropDownItem";
import { Bind } from "../../../decorators/bind.decorator";
import { CustomAttributes } from "../../../pojo/CustomAttributes";

@TypeManagerDecorator("s25-ng-editable-custom-attribute")
@Component({
    selector: "s25-ng-editable-custom-attribute",
    template: `
        <div *ngIf="init">
            <div [ngSwitch]="attributeType" class="attributeRow">
                <label for="customAttributeValue" class="ngBlock">{{ attributeName }}: </label>

                <div *ngSwitchCase="AttributeType.Boolean">
                    <s25-toggle-button
                        (modelValueChange)="onValueChange($event)"
                        name="attribute value"
                        [disabled]="readOnly"
                    ></s25-toggle-button>
                </div>

                <div *ngSwitchCase="AttributeType.Text">
                    <s25-ng-editable-text
                        *ngIf="!discreteOptions[attributeId]"
                        (valChange)="onValueChange($event)"
                        [val]="value"
                        name="attribute value"
                    ></s25-ng-editable-text>

                    <select
                        *ngIf="discreteOptions[attributeId] && multiVal === 0"
                        [ngModel]="value"
                        (ngModelChange)="onValueChange($event)"
                        class="c-input"
                        name="attribute value"
                    >
                        <option *ngFor="let opt of discreteOptions[attributeId]" [ngValue]="opt.itemId">
                            {{ opt.itemName }}
                        </option>
                    </select>

                    <s25-ng-generic-multiselect-dropdown
                        *ngIf="discreteOptions[attributeId] && multiVal === 1"
                        [items]="discreteOptions[attributeId]"
                        [onDone]="onDone"
                    >
                    </s25-ng-generic-multiselect-dropdown>
                </div>

                <div *ngSwitchCase="AttributeType.Integer">
                    <s25-ng-editable-number
                        (valChange)="onValueChange($event)"
                        [val]="value"
                        name="attribute value"
                    ></s25-ng-editable-number>
                </div>

                <div *ngSwitchCase="AttributeType.Float">
                    <s25-ng-editable-text
                        (valChange)="onValueChange($event)"
                        [val]="value"
                        name="attribute value"
                    ></s25-ng-editable-text>
                </div>

                <div *ngSwitchCase="AttributeType.LongText">
                    <s25-ng-editable-textarea
                        (valChange)="onValueChange($event)"
                        [val]="value"
                        [rows]="10"
                        [cols]="50"
                        name="attribute value"
                    ></s25-ng-editable-textarea>
                </div>

                <div *ngSwitchCase="AttributeType.URL">
                    <s25-ng-editable-text
                        (valChange)="onValueChange($event)"
                        [val]="value"
                        name="attribute value"
                    ></s25-ng-editable-text>
                </div>

                <div *ngSwitchCase="AttributeType.Date">
                    <s25-ng-editable-date
                        (valChange)="onValueChange($event.toISOString())"
                        [val]="value"
                        name="attribute value"
                    ></s25-ng-editable-date>
                </div>

                <div *ngSwitchCase="AttributeType.DateTime">
                    <s25-ng-editable-date-time
                        (valChange)="onValueChange($event)"
                        [val]="value"
                        [outputString]="true"
                        name="attribute value"
                    ></s25-ng-editable-date-time>
                </div>

                <div *ngSwitchCase="AttributeType.Time">
                    <s25-timepicker
                        (modelValueChange)="onValueChange($event.toISOString())"
                        [defaultNow]="true"
                        [modelValue]="value"
                        name="attribute value"
                    ></s25-timepicker>
                </div>

                <div *ngSwitchCase="AttributeType.Location">
                    <s25-ng-dropdown-search-criteria
                        [type]="'locations'"
                        [chosen]="value"
                        (chosenChange)="onValueChange($any($event))"
                        name="attribute value"
                    ></s25-ng-dropdown-search-criteria>
                </div>

                <div *ngSwitchCase="AttributeType.Resource">
                    <s25-ng-dropdown-search-criteria
                        [type]="'resources'"
                        [chosen]="value"
                        (chosenChange)="onValueChange($any($event))"
                        name="attribute value"
                    ></s25-ng-dropdown-search-criteria>
                </div>

                <div *ngSwitchCase="AttributeType.Organization">
                    <s25-ng-dropdown-search-criteria
                        [type]="'organizations'"
                        [chosen]="value"
                        (chosenChange)="onValueChange($any($event))"
                        name="attribute value"
                    ></s25-ng-dropdown-search-criteria>
                </div>

                <div *ngSwitchCase="AttributeType.Contact">
                    <s25-ng-dropdown-search-criteria
                        [type]="'contacts'"
                        [chosen]="value"
                        (chosenChange)="onValueChange($any($event))"
                        name="attribute value"
                    ></s25-ng-dropdown-search-criteria>
                </div>

                <div *ngSwitchCase="AttributeType.Image" class="imagePreview">
                    <s25-ng-editable-image
                        [showImage]="true"
                        [val]="value"
                        [onCommit]="imageAction"
                        (valueChange)="onValueChange($event)"
                        autoSaveUpload="true"
                    ></s25-ng-editable-image>
                </div>
            </div>
        </div>
    `,
    styles: `
        .imagePreview {
            max-width: 20em;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditableCustomAttributeComponent extends S25EditableAbstract implements OnInit, OnChanges {
    @Input() readOnly: boolean = true;
    @Input() attributeType = CustomAttributes.type;
    @Input() attributeId: number;
    @Input() attributeName: string;
    @Input() multiVal?: number = 0;

    @Input() type?: string = "";
    @Input() value?: Rules.ConditionValue;
    @Output() valChange = new EventEmitter();

    getType = () => this.type;

    init: boolean = false;
    AttributeType = CustomAttributes.type;
    discreteOptions: DropDownItem = {};

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
    }

    async ngOnInit() {
        this.setCanEdit();
        this.init = true;
        await this.setDiscreteOptions();
        super.ngOnInit();
    }

    onValueChange(e: any) {
        this.valChange.emit(e);
    }

    imageAction = (data: ModalImageI) => {
        this.valChange.emit(data);
    };

    async setDiscreteOptions() {
        const discreteOptions = await CustomAttributeService.getAllDiscreteOptions();
        for (let option of discreteOptions) {
            this.discreteOptions[option.itemId] = option.opt.map((item) => ({
                txt: item,
                val: item,
                itemName: item,
                itemId: item,
            }));
        }
    }

    setCanEdit() {
        this.readOnly = this.readOnly || CustomAttributeService.isReadOnly(this.attributeId);
    }

    @Bind
    onDone({ items }: { items: DropDownItem[] }) {
        this.valChange.emit(items);
    }

    ngOnChanges() {}
}
