//@author: nichole

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";

export interface EditableBooleanModelI {
    data: boolean;
    action?: (data: boolean) => Promise<any>;
    trueLabel: string;
    falseLabel: string;
    toggleLabel?: string;
    itemLabel?: string;
    disabled?: boolean;
}

@TypeManagerDecorator("s25-editable-boolean")
@Component({
    selector: "s25-editable-boolean",
    template: `
        <s25-toggle-button
            (modelValueChange)="this.onChange($event)"
            [modelValue]="this.model.data"
            [toggleLabel]="this.model.toggleLabel || this.model.itemLabel"
            [trueLabel]="this.model.trueLabel"
            [falseLabel]="this.model.falseLabel"
            [disabled]="this.model.disabled"
        ></s25-toggle-button>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditableBooleanComponent implements OnInit {
    @Input() model?: EditableBooleanModelI;

    @Output() modelValueChange = new EventEmitter();

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    onChange($event: any) {
        this.model.data = S25Util.coalesce($event.details, $event);
        this.modelValueChange.emit(this.model.data);
        this.cd.detectChanges();
        return this.model.action?.(this.model.data);
    }

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this;
        this.model.data = S25Util.isUndefined(this.model.data) ? undefined : S25Util.toBool(this.model.data);
    }
}
