import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25EditableAbstract } from "../s25.editable.abstract";
import { jSith } from "../../../util/jquery-replacement";

@TypeManagerDecorator("s25-ng-editable-start-end-datetime")
@Component({
    selector: "s25-ng-editable-start-end-datetime",
    template: `
        @if (init) {
            <div class="c-start-end-datetime-picker--wrapper" tabindex="0" aria-label="Occurrence Start/End Dates">
                @if (!noStartDate) {
                    <div>
                        <s25-ng-editable-date
                            class="c-start-end-datetime-picker--editable-date ngInlineBlock"
                            [readOnly]="noStartDateEdit"
                            [(val)]="candidateStartDt"
                            (valChange)="updateStartDate(true)"
                            [minDate]="minDate"
                            [maxDate]="maxDate"
                            [alwaysEditing]="alwaysEditing"
                        ></s25-ng-editable-date>
                    </div>
                }
                @if (!noStartTime) {
                    <div>
                        <s25-timepicker
                            class="timepicker ngInlineBlock"
                            step="30"
                            [(modelValue)]="candidateStartDt"
                            (modelValueChange)="updateStartDate()"
                            [inputId]="'startTimePicker'"
                        >
                        </s25-timepicker>
                    </div>
                }
                @if (startDateError) {
                    <span class="ngBold ngRed">{{ this.startDateError }}</span>
                }
                <p class="ngEventFormOccItem ngInlineBlock start-end-date-title c-margin-left--quarter">
                    {{ !this.noEndDate && this.spansMidnight ? "To" : "To" }}:
                </p>
                @if (!noEndDate) {
                    <div>
                        @if (spansMidnight) {
                            <div>
                                <s25-ng-editable-date
                                    class="c-start-end-datetime-picker--editable-date ngInlineBlock"
                                    [readOnly]="noEndDateEdit"
                                    [(val)]="candidateEndDt"
                                    (valChange)="updateEndDate(true)"
                                    [minDate]="minDate"
                                    [maxDate]="maxDate"
                                    [alwaysEditing]="alwaysEditing"
                                ></s25-ng-editable-date>
                            </div>
                        }
                    </div>
                }
                @if (!noEndTime) {
                    <div [ngClass]="{ 'spansMidnight-endTime': this.spansMidnight }">
                        <s25-timepicker
                            class="timepicker ngInlineBlock"
                            step="30"
                            [(modelValue)]="candidateEndDt"
                            (modelValueChange)="updateEndDate()"
                            [inputId]="'endTimePicker'"
                        >
                        </s25-timepicker>
                    </div>
                }
                @if (endDateError) {
                    <span class="ngBold ngRed">{{ this.endDateError }}</span>
                }
                @if (!noSpansMidnight && spansMidnightLabel) {
                    <div>
                        <s25-ng-checkbox
                            [(modelValue)]="onSameDay"
                            (modelValueChange)="spansMidnightChange()"
                            [labelId]="spansMidnightLabel"
                            [labelClass]="'inline'"
                            >{{ spansMidnightLabel }}</s25-ng-checkbox
                        >
                        @if (spansMidnight && hasSpansMidnightWarning) {
                            <div>
                                <div
                                    class="cn-alert cn-alert--warning c-margin-top--half c-margin-left--none"
                                    role="alert"
                                >
                                    <div class="cn-alert__icon cn-icon" name="alert--info">
                                        <svg class="cn-svg-icon" role="img">
                                            <title>Informational alert</title>
                                            <use
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#info"
                                            ></use>
                                        </svg>
                                    </div>
                                    <div class="cn-alert__label">
                                        <span
                                            >Warning: every single occurrence will now have the same duration you select
                                            from these initial date pickers.</span
                                        >
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditableStartEndDateTimeComponent extends S25EditableAbstract implements OnInit {
    @Input() startDatetime: Date;
    @Input() endDatetime: Date;

    @Input() minDate?: Date;
    @Input() maxDate?: Date;

    @Input() noSpansMidnightEdit: boolean;

    @Input() noStartDate: boolean;
    @Input() noStartDateEdit: boolean;

    @Input() noEndDate: boolean;
    @Input() noEndDateEdit: boolean;

    @Input() noStartTime: boolean;
    @Input() noEndTime: boolean;

    @Input() spansMidnightLabel: string;
    @Input() hasSpansMidnightWarning: boolean;
    @Input() alwaysSpansMidnight: boolean;

    @Input() timeUpdateValidate: Function;
    @Input() postTimeUpdate: Function;

    @Input() startDateUpdateValidate: (obj: any) => boolean | string;
    @Input() postStartDateUpdate: Function = () => {};

    @Input() endDateUpdateValidate: (obj: any) => boolean | string;
    @Input() postEndDateUpdate: Function = () => {};

    @Output() startDatetimeChange = new EventEmitter<Date>();
    @Output() endDatetimeChange = new EventEmitter<Date>();

    @Input() noTimeUpdated?: boolean = false;

    init = false;
    onSameDay: boolean;
    spansMidnight: boolean;
    noSpansMidnight: boolean;

    startDateError: string = "";
    endDateError: string = "";

    candidateEndDt: Date;
    endDtBean: any;

    candidateStartDt: Date;
    startDtBean: any;

    getType = () => "startEndDateTime";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.spansMidnightLabel = this.spansMidnightLabel === "" ? null : this.spansMidnightLabel;
        this.spansMidnightLabel = this.spansMidnightLabel === "false" ? "" : this.spansMidnightLabel;

        this.alwaysSpansMidnight = S25Util.toBool(this.alwaysSpansMidnight);

        this.noSpansMidnightEdit = S25Util.toBool(this.noSpansMidnightEdit);
        this.noStartDate = S25Util.toBool(this.noStartDate);
        this.noStartDateEdit = S25Util.toBool(this.noStartDateEdit);

        this.noEndDate = S25Util.toBool(this.noEndDate);
        this.noEndDateEdit = S25Util.toBool(this.noEndDateEdit);

        this.noSpansMidnight =
            this.noSpansMidnightEdit ||
            (this.noStartDate && this.noEndDate) ||
            (this.noStartDateEdit && this.noEndDateEdit);

        this.noStartTime = S25Util.toBool(this.noStartTime);
        this.noEndTime = S25Util.toBool(this.noEndTime);

        this.candidateStartDt = S25Util.date.clone(this.startDatetime);
        this.candidateEndDt = S25Util.date.clone(this.endDatetime);

        this.spansMidnight = this.spansMidnightF(this.startDatetime, this.endDatetime);

        this.startDtBean = {};
        this.endDtBean = {};

        this.spansMidnightLabel = S25Util.coalesce(this.spansMidnightLabel, "This begins and ends on the same day");

        this.init = true;
        this.cd.detectChanges();
    }

    spansMidnightF(start: Date, end: Date) {
        if (this.alwaysSpansMidnight) {
            return true;
        }
        let spansMidnight = S25Util.date.getDate(end) > S25Util.date.getDate(start);
        this.onSameDay = !spansMidnight;
        return spansMidnight;
    }

    resetError() {
        this.startDateError = "";
        this.endDateError = "";
    }

    syncEndDt(minutes: number) {
        var newEndTime;
        if (!this.spansMidnight) {
            //if not spanning midnight, then sync end date with start date date
            //and also set end date TIME to whatever the start date TIME is plus the original minutes duration
            S25Util.date.syncDateToDate(this.endDatetime, this.startDatetime); //sync enddt to startdt date
            S25Util.date.syncDateToTime(this.endDatetime, this.startDatetime); //sync times
            newEndTime = S25Util.date.addMinutes(this.endDatetime, minutes); //new date with new end time given minute duration
            if (newEndTime.getDay() !== this.startDatetime.getDay()) {
                //if this causes us to cross midnight...
                newEndTime = S25Util.date.clone(this.startDatetime); //revert to start date but at 23:59...
                newEndTime.setHours(23);
                newEndTime.setMinutes(59);
            }
            S25Util.date.syncDateToTime(this.endDatetime, newEndTime); //sync end dt to this new end time
        } else {
            S25Util.date.syncDateAll(this.endDatetime, this.startDatetime); //sync times
            S25Util.date.syncDateAll(this.endDatetime, S25Util.date.addMinutes(this.endDatetime, minutes)); //sync end dt to this new end time with minute duration added
        }

        this.candidateEndDt = S25Util.date.clone(this.endDatetime);
        this.endDtBean.date = S25Util.date.clone(this.candidateEndDt);
        this.endDtBean.refreshDatepickerBean && this.endDtBean.refreshDatepickerBean();
    }

    resetStart() {
        this.candidateStartDt = S25Util.date.clone(this.startDatetime);
        this.startDtBean.date = S25Util.date.clone(this.candidateStartDt);
        this.startDtBean.refreshDatepickerBean && this.startDtBean.refreshDatepickerBean();
    }

    resetEnd = function () {
        this.candidateEndDt = S25Util.date.clone(this.endDatetime);
        this.endDtBean.date = S25Util.date.clone(this.candidateEndDt);
        this.endDtBean.refreshDatepickerBean && this.endDtBean.refreshDatepickerBean();
    };

    updateStartDate(
        isDateChange: boolean,
        skipPostCallback: boolean,
        skipValidation: boolean,
        isDateAndTimeChange?: boolean,
    ) {
        let timeUpdated =
            !isDateChange &&
            S25Util.date.toS25ISOTimeStr(this.candidateStartDt) !== S25Util.date.toS25ISOTimeStr(this.startDatetime);

        this.resetError();
        return S25Util.all({
            isTimeValid:
                !skipValidation &&
                timeUpdated &&
                this.timeUpdateValidate &&
                this.timeUpdateValidate({ date: this.candidateStartDt, type: "start" }),
            isStartDateValid:
                !skipValidation &&
                isDateChange &&
                this.startDateUpdateValidate &&
                this.startDateUpdateValidate({ date: this.candidateStartDt, type: "start" }),
        }).then((resp) => {
            //is valid params can be (truthy) strings with error text, so only mark as truly valid if the value is literally a true boolean
            let isTimeValidBool = skipValidation || isDateChange || S25Util.coalesce(resp.isTimeValid, true) === true;
            let isStartDateValidBool =
                skipValidation || !isDateChange || S25Util.coalesce(resp.isStartDateValid, true) === true;

            if (!isTimeValidBool || !isStartDateValidBool) {
                if (resp.isTimeValid) {
                    //if truthy, it must be error text
                    this.startDateError = resp.isTimeValid;
                } else if (resp.isStartDateValid) {
                    //if truthy, it must be error text
                    this.startDateError = resp.isStartDateValid as string;
                }
                this.resetStart();
            } else {
                let minutes = S25Util.date.diffMinutes(this.startDatetime, this.endDatetime); //original minute duration
                let prevStartDatetime = S25Util.date.clone(this.startDatetime);
                S25Util.date.syncDateToDate(this.startDatetime, this.candidateStartDt);
                if (!(isDateChange && this.noTimeUpdated))
                    S25Util.date.syncDateToTime(this.startDatetime, this.candidateStartDt);
                this.resetStart(); //"reset" here will just re-assign candidate to start, which has no effect, but will also update the datepicker model which we want
                this.syncEndDt(minutes); //sync end dt (if NOT spans midnight, then this will keep the same end dt and same minute duration as before)
                if (isDateChange || isDateAndTimeChange) {
                    !skipPostCallback &&
                        this.postStartDateUpdate({
                            prevStartDatetime: prevStartDatetime,
                            startDatetime: this.startDatetime,
                        });
                }

                if (!isDateChange || isDateAndTimeChange) {
                    !skipPostCallback && this.postTimeUpdate;
                }
                this.spansMidnight = this.spansMidnightF(this.startDatetime, this.endDatetime);
            }

            this.startDatetimeChange.emit(this.startDatetime);
            this.endDatetimeChange.emit(this.endDatetime);
        });
    }

    updateEndDate(
        isDateChange: boolean,
        skipPostCallback: boolean,
        skipValidation: boolean,
        isDateAndTimeChange?: boolean,
    ) {
        let timeUpdated =
            !isDateChange &&
            S25Util.date.toS25ISOTimeStr(this.candidateEndDt) !== S25Util.date.toS25ISOTimeStr(this.endDatetime);
        this.resetError();

        return S25Util.all({
            isTimeValid:
                !skipValidation && timeUpdated && this.timeUpdateValidate?.({ date: this.candidateEndDt, type: "end" }),
            isEndDateValid:
                !skipValidation &&
                isDateChange &&
                this.endDateUpdateValidate?.({ date: this.candidateEndDt, type: "end" }),
        }).then((resp) => {
            //is valid params can be (truthy) strings with error text, so only mark as truly valid if the value is literally a true boolean
            let isTimeValidBool = skipValidation || isDateChange || S25Util.coalesce(resp.isTimeValid, true) === true;
            let isEndDateValidBool =
                skipValidation || !isDateChange || S25Util.coalesce(resp.isEndDateValid, true) === true;

            if (!isTimeValidBool || !isEndDateValidBool) {
                if (resp.isTimeValid) {
                    //if truthy, it must be error text
                    this.endDateError = resp.isTimeValid;
                } else if (resp.isEndDateValid) {
                    //if truthy, it must be error text
                    this.endDateError = resp.isEndDateValid as string;
                }
                this.resetEnd();
            } else {
                if (this.candidateEndDt < this.startDatetime) {
                    this.endDateError = "End date cannot be less than start date";
                    this.resetEnd();
                } else {
                    let prevEndDatetime = S25Util.date.clone(this.endDatetime);
                    S25Util.date.syncDateToDate(this.endDatetime, this.candidateEndDt);
                    S25Util.date.syncDateToTime(this.endDatetime, this.candidateEndDt);
                    this.resetEnd(); //"reset" here will just re-assign candidate to start, which has no effect, but will also update the datepicker model which we want
                    this.spansMidnight = this.spansMidnightF(this.startDatetime, this.endDatetime);
                    if (!isDateChange || isDateAndTimeChange) {
                        !skipPostCallback && this.postTimeUpdate;
                    }

                    if (isDateChange || isDateAndTimeChange) {
                        !skipPostCallback &&
                            this.postEndDateUpdate({ prevEndDatetime: prevEndDatetime, endDatetime: this.endDatetime });
                    }
                }
            }
            this.endDatetimeChange.emit(this.endDatetime);
        });
    }

    spansMidnightChange() {
        this.spansMidnight = !this.onSameDay;
        if (!this.spansMidnight) {
            //if not spanning midnight (AFTER change, so this is CURRENT, FINAL value)
            this.candidateEndDt = S25Util.date.syncDateToTime(
                S25Util.date.clone(this.startDatetime),
                this.candidateEndDt,
            ); //candidate end date same as start date (with end date time)
            this.updateEndDate(true, false, true); //update everything, including click-to-pick
        }
        this.cd.detectChanges();
    }

    //Used by api
    refreshDate(data: any) {
        this.candidateStartDt = S25Util.date.syncDateToTime(
            S25Util.date.clone(data.startDatetime || this.startDatetime),
            this.candidateStartDt,
        );
        this.candidateEndDt = S25Util.date.syncDateToTime(
            S25Util.date.clone(data.endDatetime || this.endDatetime),
            this.candidateEndDt,
        );

        this.updateStartDate(true, data.skipPostCallback, data.skipValidation);
        this.updateEndDate(true, data.skipPostCallback, data.skipValidation);
    }

    //used by api
    refreshDatetimes(data: any) {
        let defer = data.defer || jSith.defer();
        this.candidateStartDt = S25Util.date.clone(data.startDatetime || this.startDatetime);

        return this.updateStartDate(true, data.skipPostCallback, data.skipValidation, true).then(() => {
            this.candidateEndDt = S25Util.date.clone(data.endDatetime || this.endDatetime); //so we set end dt again here and apply it
            return this.updateEndDate(true, data.skipPostCallback, data.skipValidation, true).then(function () {
                return defer.resolve();
            });
        }); //sets candidate END dt too since start time moved
    }
}
