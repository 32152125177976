//@author: devin

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25EditableAbstract } from "../s25.editable.abstract";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-editable-textarea")
@Component({
    selector: "s25-ng-editable-textarea",
    template: `
        @if (this.readOnly) {
            <span [innerHTML]="this.val"></span>
        }

        @if (!this.readOnly) {
            <div>
                @if (!this.editing) {
                    <div
                        class="s25-editable-shared-container c-editable-text editable editable-click"
                        tabindex="0"
                        (click)="this.editMode()"
                        (keyup.enter)="this.editMode()"
                        (keyup.space)="this.editMode()"
                    >
                        <div class="s25-editable s25-data" [innerHTML]="!val && placeholder ? placeholder : val"></div>
                    </div>
                }
                @if (this.editing) {
                    <div>
                        <textarea
                            class="firstFocusEditable"
                            [minlength]="this.min"
                            [maxlength]="this.max"
                            [disabled]="this.disabled"
                            [ngModel]="this.candidateVal"
                            [rows]="this.rows"
                            [cols]="this.cols"
                            (ngModelChange)="this.update($event)"
                            (blur)="this.onInputBlur()"
                            id="{{ fieldId ?? '' }}"
                        ></textarea>
                        <div class="aw-button-group">
                            @if (this.hasCancelButton) {
                                <button
                                    class="aw-button aw-button--outline"
                                    (click)="this.cancel()"
                                    (keyup.enter)="this.cancel()"
                                >
                                    {{ this.cancelButtonText }}
                                </button>
                            }
                            @if (this.hasCommitButton) {
                                <button
                                    class="aw-button aw-button--primary"
                                    (click)="this.commit()"
                                    (keyup.enter)="this.commit()"
                                >
                                    {{ this.commitButtonText }}
                                </button>
                            }
                        </div>
                        @if (this.errorMessages) {
                            <div>
                                @for (msg of this.errorMessages; track msg) {
                                    <div class="ngRed ngBold">
                                        {{ msg }}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditableTextareaComponent extends S25EditableAbstract implements OnInit {
    getType = () => "textarea";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super(elementRef, cd, zone);
    }

    ngOnInit() {
        super.ngOnInit();
    }
}
